<template>
    <div class="px-3 md:px-0 pt-4">
        <loading-modal :isShow="isLoading" />
    </div>
</template>
<script>
import LoadingModal from '../components/LoadingModal.vue';
import { errorHandler, showErrorNotif } from '../helpers/Tools';
export default {
    name : 'PemberitahuanRedirecter',
    components: { LoadingModal },
    data(){
        return {
            isLoading : true,
        }
    },
    mounted() {
        this.loadDetailNotification();
    },
    methods : {

        async loadDetailNotification() {
            let id_pemberitahuan = this.$route.params.id_pemberitahuan
            try {
                let res = await this.$store.dispatch("readNotification", {
                    id_pemberitahuan: id_pemberitahuan
                });
                let result         = res.data;
                    this.isLoading = false;
                if(result.status == 'success') {
                    let pemberitahuan = result.data;
                    let payload = pemberitahuan.payload;
                    if(pemberitahuan.tipe == 1) {
                        this.$router.replace({
                            name: 'detailModul',
                            params: { 
                                singkatan_kategori: payload.kategori.singkatan.toLowerCase(),
                                tipe_modul        : payload.status_bayar_label,
                                id_paket          : payload.id_paket
                            } 
                        });
                    } else {
                        this.$router.push({
                            name: 'appHome',
                        });
                    }
                } else {
                    showErrorNotif(this, result.message);
                }
            } catch(error) {
                this.isLoading = false;
                this.$router.push({
                    name: 'Pemberitahuan'
                });
            }

        }
    }
}
</script>